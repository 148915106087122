import SimpleSpinner from '@/common/components/SimpleSpinner';
import { WorkOrderFormDefaultValuesType } from '@/common/hooks/useCreateWorkOrder';
import { useTemplateContext } from '@/context/TemplateContext';
import { WorkOrderDoneStatus } from '@/modules/workOrders/types/workOrder';
import { TASK } from '@/utils/i18n/constants';
import useTranslation from '@/utils/i18n/useTranslation';
import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import { Box } from '@chakra-ui/react';
import { FC } from 'react';
import WorkOrderDetail from '../../WorkOrderDetail';
import WorkOrderForm from '../../WorkOrderForm/internal/WorkOrderForm.component';
import { WorkOrderDetailAndFormProps } from './WorkOrderDetailAndForm.types';

const WorkOrderDetailAndForm: FC<WorkOrderDetailAndFormProps> = (
  props: WorkOrderDetailAndFormProps
) => {
  const { isMobile } = useScreenInfos();

  return (
    <Box
      h='100%'
      bg='neutral.0'
      w='full'
      overflowY={isMobile ? 'unset' : 'auto'}
      minW={{ base: 'auto', md: '450px' }}
      data-testid='work-order-detail'
    >
      <InternalWorkOrderDetailAndForm {...props} />
    </Box>
  );
};

const InternalWorkOrderDetailAndForm: FC<WorkOrderDetailAndFormProps> = (props) => {
  const {
    updateWorkOrder,
    deleteWorkOrder,
    onAddCustomFieldFileValues,
    onRemoveCustomFieldFileValue,
    onUpdateCustomFieldFileValue,
    cancelWorkOrderCreation,
    displayUpdateWorkOrderForm,
    onClickNotificationButton,
    onClickCopyWorkOrderButton,
    workOrder,
    isEditing,
    customFieldFileValues,
    getFileUploadUrls,
    onUpdatePart,
    onCreateWorkOrderTextSuggest,
    createCheckList,
    deleteCheckList,
    updateCheckList,
    moveWorkOrder,
    onBackButtonClicked,
    isLoading,
    onWorkOrderClicked,
  } = props;

  const { t, t_ns } = useTranslation(TASK);
  const { targetWorkOrderTemplate: template } = useTemplateContext();
  const isDoneWorkOrder = workOrder?.status === WorkOrderDoneStatus;

  if (isLoading) return <SimpleSpinner />;
  if (!template) return null;
  if (!workOrder) return null;

  if (isEditing) {
    return (
      <WorkOrderForm
        key={workOrder.id}
        // フォームでは、チェックリストを入力せず追加・削除のみ行えるが、チェックリスト単体のミューテーションを入力値変更のタイミングで行っていて、タスク全体の更新では送らない
        submit={(payload) => updateWorkOrder({ ...payload, checkLists: undefined })}
        cancel={cancelWorkOrderCreation}
        // 本来不要なマッピングなのでWorkOrder詳細がcolocating fragmentできるようになったら削除 TODO
        workOrder={workOrder as unknown as WorkOrderFormDefaultValuesType}
        template={template}
        customFieldFileValues={customFieldFileValues}
        title={t_ns('edit-task')}
        submitButton={t('actions.save')}
        onAddCustomFieldFileValues={onAddCustomFieldFileValues}
        onRemoveCustomFieldFileValue={onRemoveCustomFieldFileValue}
        onUpdateCustomFieldFileValue={onUpdateCustomFieldFileValue}
        onUpdatePart={onUpdatePart}
        deleteCheckList={deleteCheckList}
        createCheckList={createCheckList}
        displayStatus={isDoneWorkOrder}
        shouldConfirmStatusChange={true}
      />
    );
  }

  return (
    <WorkOrderDetail
      key={workOrder.id}
      onCreateWorkOrderTextSuggest={onCreateWorkOrderTextSuggest}
      workOrder={workOrder}
      template={template}
      customFieldFileValues={customFieldFileValues}
      displayUpdateWorkOrderForm={displayUpdateWorkOrderForm}
      updateWorkOrder={updateWorkOrder}
      deleteWorkOrder={deleteWorkOrder}
      onAddCustomFieldFileValues={onAddCustomFieldFileValues}
      onRemoveCustomFieldFileValue={onRemoveCustomFieldFileValue}
      onUpdateCustomFieldFileValue={onUpdateCustomFieldFileValue}
      onUpdatePart={onUpdatePart}
      getFileUploadUrls={getFileUploadUrls}
      onClickNotificationButton={onClickNotificationButton}
      onClickCopyWorkOrderButton={onClickCopyWorkOrderButton}
      updateCheckList={updateCheckList}
      moveWorkOrder={moveWorkOrder}
      onBackButtonClicked={onBackButtonClicked}
      onWorkOrderClicked={onWorkOrderClicked}
    />
  );
};

export default WorkOrderDetailAndForm;
