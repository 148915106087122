import { WorkOrderCard_WorkOrderFragment } from '../components/WorkOrderCard/index.generated';

export const findWorkOrderIdByOffset = (
  workOrders: WorkOrderCard_WorkOrderFragment[],
  currentWorkOrderId: number,
  offset: number
) => {
  const currentIndex = workOrders.findIndex((workOrder) => workOrder.id === currentWorkOrderId);
  if (currentIndex === -1) return undefined;
  const nextWorkOrder = workOrders[currentIndex + offset];
  return nextWorkOrder ? nextWorkOrder.id : undefined;
};
