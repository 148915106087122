import { TASK } from '@/utils/i18n/constants';
import useTranslation from '@/utils/i18n/useTranslation';
import { Button } from '@chakra-ui/react';
import { useSelectWorkOrderTemplate } from '../../templates/SelectWorkOrderTemplate';

const WorkOrderTemplateSelectButton = ({
  workOrderTemplates,
  onTemplateClick,
}: {
  workOrderTemplates: { id: number; name: string }[]; // TODO: fragmentで型を取得できるようにする
  onTemplateClick: (id: number) => void;
}) => {
  const { t_ns } = useTranslation(TASK);
  const { element, open } = useSelectWorkOrderTemplate({
    templates: workOrderTemplates,
    onSelect: onTemplateClick,
  });

  const onMenuButtonClick = () => {
    if (workOrderTemplates.length === 0) {
      throw new Error('テンプレートがありません');
    }

    if (workOrderTemplates.length === 1) {
      onTemplateClick(workOrderTemplates[0].id);
      return;
    }

    open();
  };

  return (
    <>
      <Button variant='outline' colorScheme='primary' size='sm' onClick={onMenuButtonClick}>
        {t_ns('new-link-task')}
      </Button>

      {element}
    </>
  );
};

export default WorkOrderTemplateSelectButton;
