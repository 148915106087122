import { Flex, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

type Props = {
  title?: string;
  children: ReactNode;
  menu?: ReactNode;
};

const DetailSection = ({ title, children, menu }: Props) => {
  return (
    <Flex py='8px' px='16px' bgColor='gray.0' borderRadius='md' direction='column' gap='8px'>
      <Flex direction='row' justifyContent='space-between' alignItems='center'>
        {title && (
          <Text fontSize='16px' fontWeight='600' lineHeight='24px' textAlign='left'>
            {title}
          </Text>
        )}
        {menu}
      </Flex>

      {children}
    </Flex>
  );
};

export default DetailSection;
