import SimpleSpinner from '@/common/components/SimpleSpinner';
import { useScrollToTargetHeight } from '@/common/hooks/useScrollToTargetHeight';
import { useWorkOrderPageContext } from '@/context/WorkOrderPageContext';
import { useWorkOrderStatus } from '@/modules/workOrders/hooks/useWorkOrderStatus';
import { TASK } from '@/utils/i18n/constants';
import useTranslation from '@/utils/i18n/useTranslation';
import { useListAndDetail } from '@/utils/mobiles/useListAndDetail';
import { Box, Tab, TabList, TabPanels, Tabs } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { WorkOrderCardListTabsProps } from './WorkOrderCardListTabs.types';
import { WorkOrderDoneCardListTab } from './WorkOrderDoneCardListTab';
import { WorkOrderNotDoneCardListTab } from './WorkOrderNotDoneCardListTab';

const WorkOrderCardListTabs: FC<WorkOrderCardListTabsProps> = (
  props: WorkOrderCardListTabsProps
) => {
  const { onWorkOrderClicked, targetWorkOrderId, workOrderActions, hidden } = props;
  const {
    loadMoreDoneWorkOrders,
    doneWorkOrders = [],
    paramsHandler,
    notDoneWorkOrdersMap,
    isLoadingWorkOrders,
    setIsLoadingWorkOrders,
    doneWorkOrdersTotalCount,
  } = useWorkOrderPageContext();
  const { setUrlHash } = useListAndDetail();

  const { t } = useTranslation(TASK);
  const { getStatusLabel } = useWorkOrderStatus();

  const { isDone, setIsDone, page } = paramsHandler;
  const [tabIndex, setTabIndex] = useState(0);

  const scrollRef = useScrollToTargetHeight(hidden);

  const handleTabsChange = (index: number) => {
    setIsLoadingWorkOrders(true);
    setTabIndex(index);
    if (index === 0) {
      setIsDone(false);
    } else {
      setIsDone(true);
    }

    setUrlHash('');
  };

  useEffect(() => {
    setTabIndex(isDone ? 1 : 0);
  }, [isDone]);

  return (
    <Box
      minW={{ base: 'full', md: '350px' }}
      maxW={{ base: 'full', md: '350px' }}
      overflowY='scroll'
      hidden={hidden}
    >
      <Tabs w='full' index={tabIndex} onChange={handleTabsChange}>
        <TabList position='sticky' top={0} background='chakra-body-bg' zIndex={1}>
          <Tab w='full'> {t('status.todo')}</Tab>
          <Tab w='full'>{getStatusLabel('done')}</Tab>
        </TabList>
        {isLoadingWorkOrders ? (
          <SimpleSpinner />
        ) : (
          <TabPanels p={0}>
            <WorkOrderNotDoneCardListTab
              onWorkOrderClicked={onWorkOrderClicked}
              notDoneWorkOrdersMap={notDoneWorkOrdersMap}
              targetWorkOrderId={targetWorkOrderId}
              workOrderActions={workOrderActions}
              ref={scrollRef}
            />
            <WorkOrderDoneCardListTab
              doneWorkOrders={doneWorkOrders}
              targetWorkOrderId={targetWorkOrderId}
              workOrderActions={workOrderActions}
              onWorkOrderClicked={onWorkOrderClicked}
              doneWorkOrdersTotalCount={doneWorkOrdersTotalCount}
              page={page}
              handleLoadMore={loadMoreDoneWorkOrders}
              ref={scrollRef}
            />
          </TabPanels>
        )}
      </Tabs>
    </Box>
  );
};

export default WorkOrderCardListTabs;
